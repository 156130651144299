<template>
    <v-dialog v-model="errorDialog" max-width="700">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn color="primary" icon @click="getQueryResults($props.qid)" :loading="loading" v-on="{ ...tooltip, ...dialog }">
                        <v-icon color="error" dark v-on="{ ...tooltip }">mdi-alert-circle</v-icon>
                    </v-btn>
                </template>
                <div class="d-flex flex-column align-center">
                    <span class="subtitle-1">FAILED</span>
                    Click to view error message
                </div>
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title>
                <div class="primary--text"><v-icon class="mr-1">mdi-information-variant</v-icon>SQL Error</div>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text>
                <div class="subtitle-2 error--text">{{ error }}</div>
                <div v-if="query" class="mt-3">
                    <div style="background-color: rgba(23, 168, 193, 0.1); width:100%">
                        <span class="mb-0 mt-0 ml-2 font-weight-bold caption" style="color:rgba(0, 0, 0, 0.54)">Query</span>
                    </div>
                    <MonacoEditor
                        class="mb-3"
                        :style="{ height: queryLines }"
                        v-model="query"
                        language="sql"
                        :options="{
                            minimap: { enabled: false },
                            fontSize: 14,
                            readOnly: true,
                            renderLineHighlight: false,
                            automaticLayout: true,
                            autoIndent: true,
                            lineNumbersMinChars: 3,
                            scrollbar: { vertical: 'hidden' },
                            scrollBeyondLastLine: false,
                            smoothScrolling: true
                        }"
                    />
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="errorDialog = false">cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const MonacoEditor = () => import('vue-monaco')

export default {
    name: 'QueryErrorDialog',
    props: {
        qid: Number
    },
    components: {
        MonacoEditor
    },
    data() {
        return {
            errorDialog: false,
            error: null,
            query: null,
            loading: false,
            readOnly: false
        }
    },
    methods: {
        getQueryResults: function(qid) {
            this.$data.loading = true
            this.$axios
                .get(`/queries/${qid}/results`)
                .then(response => {
                    this.$emit('success', { value: true })
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.preprocess_error) {
                        this.$data.error = error.response.data.preprocess_error
                        this.$data.query = ''
                        this.$data.errorDialog = true
                    } else if (error.response && error.response.data && error.response.data.credentials_error) {
                        this.$data.error = error.response.data.credentials_error
                        this.$data.query = ''
                        this.$data.errorDialog = true
                    } else if (error.response && error.response.data && error.response.data.sql_error) {
                        this.$data.error = error.response.data.sql_error
                        this.$data.query = error.response.data.actual_sql
                        this.$data.errorDialog = true
                    }
                })
                .finally(() => {
                    this.$data.loading = false
                })
        }
    },
    computed: {
        queryLines() {
            if (this.$data.query) {
                if (this.$data.query.split('\n').length === 1) {
                    return '32px'
                } else {
                    return this.$data.query.split('\n').length * 22 + 'px'
                }
            }
            return null
        }
    }
}
</script>
