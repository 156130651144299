var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var dialog = ref.on
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var tooltip = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    color: "primary",
                                    icon: "",
                                    loading: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.getQueryResults(_vm.$props.qid)
                                    }
                                  }
                                },
                                Object.assign({}, tooltip, dialog)
                              ),
                              [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    { attrs: { color: "error", dark: "" } },
                                    Object.assign({}, tooltip)
                                  ),
                                  [_vm._v("mdi-alert-circle")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-center" },
                    [
                      _c("span", { staticClass: "subtitle-1" }, [
                        _vm._v("FAILED")
                      ]),
                      _vm._v(" Click to view error message ")
                    ]
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.errorDialog,
        callback: function($$v) {
          _vm.errorDialog = $$v
        },
        expression: "errorDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "primary--text" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v("mdi-information-variant")
                ]),
                _vm._v("SQL Error")
              ],
              1
            )
          ]),
          _c("v-divider", { staticClass: "mb-1" }),
          _c("v-card-text", [
            _c("div", { staticClass: "subtitle-2 error--text" }, [
              _vm._v(_vm._s(_vm.error))
            ]),
            _vm.query
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "background-color": "rgba(23, 168, 193, 0.1)",
                          width: "100%"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "mb-0 mt-0 ml-2 font-weight-bold caption",
                            staticStyle: { color: "rgba(0, 0, 0, 0.54)" }
                          },
                          [_vm._v("Query")]
                        )
                      ]
                    ),
                    _c("MonacoEditor", {
                      staticClass: "mb-3",
                      style: { height: _vm.queryLines },
                      attrs: {
                        language: "sql",
                        options: {
                          minimap: { enabled: false },
                          fontSize: 14,
                          readOnly: true,
                          renderLineHighlight: false,
                          automaticLayout: true,
                          autoIndent: true,
                          lineNumbersMinChars: 3,
                          scrollbar: { vertical: "hidden" },
                          scrollBeyondLastLine: false,
                          smoothScrolling: true
                        }
                      },
                      model: {
                        value: _vm.query,
                        callback: function($$v) {
                          _vm.query = $$v
                        },
                        expression: "query"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      _vm.errorDialog = false
                    }
                  }
                },
                [_vm._v("cancel")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }